.nav {
  position: fixed;
  top: 0;
  width: calc(100% - 40px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  height: 50px;
  transition: background-color 0.5s ease;
  background-color: transparent;
  background-image: linear-gradient(180deg,rgba(0,0,0,.7) 10%,transparent);
  z-index: 2;
}
.nav__center {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 90px;
}
.nav__black {
  background-color: #000;
}

.nav__menuBtn {
  background: none;
  border: none;
  cursor: pointer;
}

.nav__logo {
  max-width: 100px;
  cursor: pointer;
}

.login-avatar{
    color: white;
    border-radius: 50%;
    border: 2px white solid;
    padding: 6px;
    height: 15px;
    margin-left: 15px;
    font-size: 14px;
    width: 15px;
    text-align: center;
}

.nav__right {
    align-items: center;
    display: flex;
    flex-grow: 1;
    height: 100%;
    justify-content: flex-end;
    position: absolute;
    right: 4%;
    top: 0;
}

.nav__search {
  position: relative;
}

.nav__searchIcon,
.nav__closeBtn {
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
}


.nav__searchBar {
  max-height: 40px;
  border-radius: 2px;
  display: flex;
  align-items: center;
}

.nav__searchBar input {
  border: none;
  background-color: #000;
  outline: none;
  padding: 8px;
  flex: 1;
}

.img-size-height {
  height: 18px;
}

.nav__closeBtn {
  margin-right: 8px;
  font-size: 18px;
  color: #555;
}

.nav__profileIcon {
  background: none;
  border: none;
  cursor: pointer;
}

.nav__searchBar {
  position: relative;
  width: 0;
  overflow: hidden;
  width: 100%;
  transition: width 0.3s ease-in-out;
  
}

.nav__searchBar input {
  width: 150px;
  border: 1px solid transparent;
  color: white;
  padding: 8px;
  transition: border-color 0.3s ease;
  font-size: 14px;
}

.nav__searchBar input:focus {
  outline: none;
  color: #ccc;
}

.nav__closeBtn {
  border: none;
  background: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}

.nav__searchBar.open {
  width: 250px;
  background-color: #000;  
  border:  1px solid #c6c6c6;
}

.nav__searchBar.close {
  width: 30px;
}

.nav__searchBar input::placeholder {
  color: #c6c6c6;
  text-align: left;
}
.profile_main{
  position: relative;
  margin-left: 8px;
}
.profile_btn{
  background: transparent;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 50%;
  padding: 2.5px 6px;
}
.profile_btn:hover{
  cursor: pointer;
}
.profile_dropdown{
  position: absolute;
  top: 30px;
  right: 0px;
  background: #000;
  /* width: 200px; */
}
.profile_dropdown button{
  background: #000;
  color: #fff;
  border: 1px solid #000;
  width: 100%;
  padding: 14px 20px;
  font-family: 'interstate', sans-serif;
  font-size: 16px;
  box-shadow: 2px 3px 45px 20px #2525257a;
  /* border-radius: 10px */
}

.profile_dropdown button:hover{
  background: #333;
  border-color: #333;
  cursor: pointer;
}
.search__icon {
  margin-left: 4px;
}



@media(max-width: 767px) {
  .nav__logo {
    max-width: 75px;
  }
  .nav__menuBtn img {
    height: auto;
    width: 85px;
  }
}
.videotag {
  position: relative;
  top: 2;
  bottom: 1vw;
  width: 100%;
  height: 100%;
  outline: none;
}

.fullscreen-video {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.video-js {
  border-radius: 6px;
}

.video-js .vjs-control {
  width: 5em;
}

.vjs-has-started .vjs-control-bar,
.vjs-audio-only-mode .vjs-control-bar {
  position: fixed;
  opacity: 1 !important;
  bottom: 30px;
  background: transparent;
  padding: 20px;
}

.video-js .vjs-control-bar {
  height: 10em;
}

/* 
.video-js .vjs-control {
    position: relative;
    text-align: center;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 8em;
    flex: none;
} */

.video-js .vjs-control.seek_btn {
  font-size: 22px;
  width: 4em;
}

.vjs-button > .vjs-icon-placeholder:before {
  font-size: 3.8em;
}

.video-js .vjs-time-control {
  font-size: 1.9em;
}

@media only screen and (max-width: 574px) {
  .ReactModal__Overlay--after-open .video-js.vjs-default-skin {
    position: absolute;
    /* top: 35%; */
  }
  .vjs-control.vjs-button.seek_btn {
    display: none;
  }
  .vjs-fullscreen-control.vjs-control.vjs-button {
    display: none;
  }
}

.vjs-quality-picker-container {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.vjs-quality-picker-dropdown {
  background: #333;
  color: #fff;
  border: 1px solid #444;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  font-size: 14px;
}

.vjs-quality-picker-dropdown option {
  background: #333;
  color: #fff;
}

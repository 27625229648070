/* .gridContainer{
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
} */
.card_view{
    padding: 10px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: calc(25% - 20px) !important;
    font-size: 32px;
    position: relative;
    display: inline-block;
}
@media(max-width: 1024px) {
    .card_view {
        width: calc(33% - 20px) !important;
    }
}
@media(max-width: 767px) {
    .card_view {
        width: calc(50% - 20px) !important;
    }
}
* {
  margin: 0;
  padding: 0;
  /* -webkit-box-sizing: inherit; */
  /* box-sizing: inherit; */
}

.App {
  overflow-x: hidden;
}


.ReactModal__Content {
  /* background-color: black !important;
    padding: 0px !important;
    width: 99vw !important;
    overflow: hidden; */
  border: none !important;
}

.abdull .ReactModal__Content--after-open {
  position: relative !important;
  inset: 0px !important;
  background: rgb(255, 255, 255) !important;
  overflow: none !important;
  outline: none !important;
  padding: 0px !important;
}

.abdull2 .ReactModal__Content--after-open {
  position: relative !important;
  inset: 0px !important;
  background: rgb(255, 255, 255) !important;
  overflow: none !important;
  outline: none !important;
  padding: 0px !important;
}

.abdull2 .modal-banner {
  background-repeat: no-repeat;
  background-position: center;
}

/* 
  .row {
    margin-left: 10px;
    margin-top: 40px;
    color: white;
  } */

.row h2 {
  margin-left: 15px;
}

.row__posters {
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  padding: 20px;
  gap: 10px;
}

/* Style for the video player when hovering */
.hovered-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

/* Hide the video player by default */
.hovered-video video {
  display: none;
}



.row__posters::-webkit-scrollbar {
  display: none;
}

.row__poster {
  width: 260px;
  /* height: 319px; */
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
  /* max-height: 145px; */
  transition: transform 400ms;
  /* margin-right: 10px; */
}

.row__poster:hover {
  /* transform: scale(1.5); */
  /* position: absolute; */
}


.row__posterLarge_hover {
  /* transform: scale(1.3); */
  position: absolute;
  background-color: black;
  width: calc(100% - 20px) !important;
  border-radius: 6px;
  box-shadow: inset 0 0 81px #000;
  border: 1px solid cyan;
  z-index: 3;
  transition: opacity 0.3s ease-in-out;
  /* height: 450px;
    max-height: 195px; */
}

/* .slick-slide.slick-active.slick-current .row__posterLarge_hover {
    left: 30%;
  } */
/* .slick-list .slick-track .slick-slide.slick-active:last-child {
    background: #CACACA !important;
  } */

/* .slick-active:last-child{
    background: black;
  } */

.row__posterLarge {
  width: 100%;
  /* height: 450px; */
  /* max-height: 195px; */
  aspect-ratio: 16/9;
}

.youtube {
  display: block;
  margin: 0 auto;
}



.carousel-container {
  width: calc(95% + 20px);
  margin: auto;
  padding-top: 40px;
  /* padding-bottom: 100px; */
  overflow: visible;
}

.slick-slider,
.slick-track {
  overflow: visible;
}

.carousel-container h2 {
  color: #2fc0d1;
  text-transform: uppercase;
  margin-left: 35px;
  margin-bottom: 20px;
}

.carousel-container h2 a {
  color: #fff;
  text-decoration: none;
  margin-left: 15px;
}

.arrow_right {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  margin-left: 4px;
  position: relative;
  z-index: -1;
}

.slick-list {
  overflow: visible !important;
}

/* .slick-prev {
    left: 1px !important;
  }
  .slick-next {
    right: 12px !important;
  } */
.card {
  padding: 10px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: calc(100% - 20px) !important;
  font-size: 32px;
  position: relative;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.prev {
  left: 10px;
}

.slick-prev:before,
.slick-next:before {
  font-size: 32px !important;
}

@media only screen and (max-width: 1440px) {
  .row__posterLarge_hover {
    /* max-height: 230px; */
  }

  .row__posterLarge {
    /* max-height: 230px; */
  }

}

@media only screen and (max-width: 1024px) {
  .slick-prev {
    left: -15px;
  }

  .slick-next {
    right: -15px;
  }
}

@media only screen and (max-width: 768px) {
  .row__posterLarge_hover h2 {
    bottom: 2px;
    left: 3px;
  }

  .row__posterLarge_hover div[style*="top"] {
    top: 35% !important;
  }

  .row__posterLarge {
    /* max-height: 145px; */
  }

  .row__posterLarge_hover {
    /* max-height: 145px; */
  }

  .carousel-container h2 {
    margin-left: 12px;
  }
}

@media only screen and (max-width: 574px) {
  .row__posterLarge {
    /* max-height: 145px; */
  }

  .row__posterLarge_hover {
    /* max-height: 214px; */
  }

  .row__posterLarge_hover div[style*="top"] {
    top: 40% !important;
  }

  .carousel-container {
    padding-top: 40px;
    padding-bottom: 40px;

  }

  .carousel-container h2 {
    font-size: 20px !important;
  }

  .carousel-container button {
    font-size: 15px !important;
  }

  .arrow_right {
    padding: 3px;
  }

  .row__posterLarge {
    /* max-height: 214px; */
    /* width: 380px; */
    /* height: 450px; */
  }
}
.banner {
  color: #fff;
  background-size: 100% 100vh;
  background-position: 50% 10%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: calc(100vh - 70px);
  margin-top: 70px;
}

.modal-banner {
  color: #fff;
  height: 85vh;
  box-shadow: inset 0 0 81px #000;
  position: relative;
}

.banner__contents {
  margin-left: 45px;
  margin-top: auto;
  margin-right: 45px;
  margin-bottom: 50px;
}

.banner__title {
  font-size: 3rem;
  font-weight: 800;
  margin-bottom: 0;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

.banner__description {
  width: 45rem;
  line-height: 1.5;
  padding-top: 1rem;
  font-size: 18px;
  max-width: 360px;
  background-color: transparent;
  letter-spacing: 1.5px;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

.bg-shadow {
  box-shadow: 6px 4px 35px 0px #000000;
  background-color: #3d3b3b;
  padding: 35px;
  border-radius: 10px;
  height: 300px;
  width: calc(100% - 160px);
  position: absolute !important;
  bottom: -76px;
  top: unset !important;
}

.ReactModal__Overlay--after-open {
  background-color: #000000 !important;
  z-index: 2;
}
.netflix-homepage .video-js .vjs-tech {
  height: 100vh;
}

.netflix-homepage .video-item .vjs-fluid:not(.vjs-audio-only-mode) {
  padding-top: 46%;
}

.banner__button {
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #2fc0d1;
  font-weight: 700;
  margin-right: 1rem;
  width: 150px;
  padding-top: 0.95rem;
  padding-bottom: 0.95rem;
  border: 2px solid #2fc0d1;
}

.meta_container {
  display: flex;
  justify-content: space-between;
}

.banner__info-column {
  color: #fff;
  box-shadow: inset;
}

.banner__metadata p {
  margin-top: 10px;
}

.banner__buttons {
  margin-top: 40px;
}

.movie_details {
  padding-right: 20px;
  top: 9px;
  position: relative;
}

.hollow-btn {
  background-color: transparent;
  border-color: #fff;
  border: 2px solid #fff;
  color: #fff;
}

.banner__button:hover {
  color: #000;
  background-color: cyan;
  transition: all 0.2s;
  border: 2px solid cyan;
}

/* 
.banner__fadeBottom {
  background: linear-gradient(77deg,rgba(0,0,0,.6),transparent 85%);
  bottom: 0;
  left: 0;
  opacity: 1;
  position: absolute;
  right: 26.09%;
  top: 0;
  transition: opacity .5s;
  opacity: 0.71;
  height: 90vh;
  width: 100%;
} */

.banner__description {
  font-size: 16px;
}

.video__banners {
  position: absolute;
  width: 100%;
  height: 90vh;
  object-fit: cover;
  z-index: -1;
}

.featured_heading {
  margin: 25px;
  margin-left: calc(2.5% + 25px);
  color: #2fc0d1;
  text-transform: uppercase;
  padding-top: 70px;
}

@media only screen and (max-width: 1024px) {
  .featured_heading {
    margin: 25px;
    margin-left: calc(0px + 25px);
    color: #2fc0d1;
    text-transform: uppercase;
    padding-top: 70px;
  }

  .banner {
    height: 60vh;
  }

  .video__banners {
    position: absolute;
    width: 100%;
    height: 60vh;
    object-fit: cover;
    z-index: -1;
  }
}

@media only screen and (max-width: 574px) {
  .banner__title {
    font-size: 1.25rem;
    font-weight: 600;
  }
  .netflix-homepage .video-item .vjs-fluid:not(.vjs-audio-only-mode) {
    padding-top: calc(100% - 50px);
    padding-bottom: calc(100% - 25px);
  }
  .banner__contents {
    margin-left: 10px;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .banner {
    background-size: 400px;
    background-repeat: no-repeat;
    height: 27vh;
    align-items: end;
  }

  .banner__description {
    display: none !important;
  }

  .banner__buttons {
    margin-top: 12px;
  }

  .banner__button {
    cursor: pointer;
    border: none;
    border-radius: 5px;
    /* background-color: #2fc0d1; */
    font-weight: 500;
    font-size: 14px;
    margin-right: 1rem;
    width: auto;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-right: 0.95rem;
    padding-left: 0.95rem;
    border: 2px solid #2fc0d1;
  }

  .btn.banner__button.hollow-btn {
    display: none;
  }
}

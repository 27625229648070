.bg-login {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: black;
    color: cyan;
    font-family: Arial, sans-serif;
}

.flip-card {
    width: 300px;
    height: fit-content;
    perspective: 1000px;
    cursor: pointer;
    border-radius: 5px;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 344px;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.flip-card.flipped .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background-color: black;
}

.flip-card-front {
    background-color: black;
}

.flip-card-back {
    background-color: black;
    transform: rotateY(180deg);
}

/* Styling for headings */
h2 {
    color: white;
    font-size: 16px;
}

/* Styling for form elements (input, button, etc.) */
/* Customize styles as needed */

/* Example input styling */
.input-custom {
    width: calc(100% - 20px);
    padding: 10px;
    margin: 10px 0px;
    color: grey;
    text-align: center;
    font-weight: 800;
    border: 1px solid #ccc;
    border-radius: 2px;
    font-size: 16px;
}

::-webkit-input-placeholder {
    text-align: center;
}

:-moz-placeholder {
    text-align: center;
}

/* Example button styling */
.btn-custom {
    width: 50%;
    padding: 10px 20px;
    margin: 10px 0px;
    border: none;
    border-radius: 2px;
    background-color: #41c2d1;
    font-weight: bold;
    color: #000;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
}

.full {
    width: calc(100% + 2px);
}
.mr10{
    margin-right: 10px;
}
.ml10{
    margin-left: 10px;
}

.btn-custom:hover {
    background-color: #28b0bf;
}

.login__btns {
    display: flex;
    justify-content: center;
    align-items: center;
}

.error-message {
    position: absolute;
    top: -40px;
    width: 100%;
    color: rgb(245, 72, 72);
    font-size: 15px;
}
.password-error-message {
    position: absolute;
    top: -54px;
    width: 100%;
    color: rgb(245, 72, 72);
    font-size: 15px;
}
.success-message{
    position: absolute;
    top: -40px;
    width: 100%;
    color: rgb(90, 235, 90);
    font-size: 15px;
}
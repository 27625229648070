.terms-container {
    text-align: center;
    margin: 0 auto;
    max-width: 80%;
    padding: 20px;
    padding-top: 100px;
}

.terms-title {
    margin-top: 50px;
    font-size: 54px;
    font-weight: bold;
    color: white;
}

.terms-content {
    color: white;
    text-align: left;
    font-size: 22px;
    line-height: 1.7;
    margin-top: 20px;
    text-align: justify;
}


/* .footer {
    background-color: #000;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    margin: 20px;
}

.footer__section {
    flex: 1;
}

.footer__column {
    margin: 0 20px;
    width: 25%;
}


.footer__description {
    font-size: 14px;
    margin-top: 10px;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.footer__links {
    list-style-type: none;
    padding: 0;
}

.footer__links li {
    margin-bottom: 15px;
}

.footer__links a {
    color: #fff;
    text-decoration: none;
}

@media only screen and (max-width: 1024px) {
    .footer__column {
        margin: 0 20px;
        width: 50%;
    }

} */


.footer__logo {
	width: 100px;
	height: auto;
}
.footer {
	background-color: #151515;
	padding: 80px 0;
	margin-top: 70px;
}

.container {
	max-width: 1400px;
	margin-right: auto;
	margin-left: auto;
	padding-right: var(--bs-gutter-x, .75rem);
	padding-left: var(--bs-gutter-x, .75rem);
	width: 100%;
}

.row {
	display: flex;
	flex-wrap: wrap;
	margin-top: calc(0 * -1);
	margin-right: calc(1.5rem/ -2);
	margin-left: calc(1.5rem/ -2);
}
.row>* {
	flex-shrink: 0;
	width: 100%;
	max-width: 100%;
	padding-right: calc(1.5rem/ 2);
	padding-left: calc(1.5rem/ 2);
	margin-top: 0;
}

ul {
	list-style: none;
	padding: 0;
}

.footer-col {
	flex: 0 0 auto;
	width: calc(33.33% - 24px);
}

/* .footer-col:first-child{
	width: 300px;
}

.footer-col:last-child{
	width: 300px;
	margin-left: 50px;
} */
.footer-col p {
	margin: 20px 0;
	color: white;
}
.footer-col h4 {
	font-size: 18px;
	color: #FFF;
	text-transform: capitalize;
	margin-bottom: 35px;
	font-weight: 500;
	position: relative;
}

.footer-col h4::before {
	content: "";
	position: absolute;
	left: 0;
	bottom: -10px;
	background-color: cyan;
	width: 50px;
	height: 2px;
}

.footer-col ul li:not(:last-child) {
	margin-bottom: 10px;
}

.footer-col ul li a {
	color: #DDD;
	display: block;
	font-size: 1rem;
	font-weight: 300;
	text-transform: capitalize;
	text-decoration: none;
	transition: all 0.3s ease;
}

.footer-col ul li a:hover {
	color: #FFF;
	padding-left: 7px;
}

.footer-col .social-links a {
	color: #FFF;
	background-color: rgba(255, 255, 255, 0.2);
	display: inline-block;
	height: 40px;
	width: 40px;
	border-radius: 50%;
	text-align: center;
	margin: 0 10px 10px 0;
	line-height: 40px;
	transition: all 0.5s ease;
}

.footer-col .social-links a:hover {
	color: #151515;
	background-color: #FFF;
}

/* @media(max-width: 1340px) {
		
	.footer-col {
		flex: 0 0 auto;
		width: 47%;
		margin-bottom: 30px;
	}
} */

@media(max-width: 1024px) {
	
}

@media(max-width: 767px) {
	.footer-col {
		width: 50%;
		margin-bottom: 30px;
	}
}

@media(max-width: 574px) {
	.container {
	  max-width: 365px !important;
	}
	.footer-col {
		width: 100%;
	}
}

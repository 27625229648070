.scrollToTopButton {
    position: fixed;
    bottom: 20px; /* Adjust this value to control the distance from the bottom */
    right: 20px; /* Adjust this value to control the distance from the right */
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 1; /* Ensure it appears above other content */
    
    color: aliceblue;
  }
  
  .scrollToTopButton:hover {
    transform: scale(1.1); /* Increase size on hover */
  }
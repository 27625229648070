/* DropdownMenu.css */

.dropdown-menu {
  position: relative;
  box-shadow: 2px 3px 45px 20px #2525257a;
}

.hamburger-icon {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 30px;
  height: 30px;
  justify-content: space-between;
  margin-right: 10px;
}

.bar {
  width: 30px;
  height: 4px;
  background-color: #333;
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: none;
}

.open ul {
  display: block;
}

.dropdown-menu.open {
  background-color: black;
  width: 280px;
  position: absolute;
  margin-top: 10px;
  border-radius: 10px;
  max-height: 320px;
  overflow-y: auto;
  z-index: 6;
}

.dropdown-menu ul {
  padding: 14px 0px
}
.dropdown-menu ul li {
  padding: 14px 20px;
  color: white;
  text-decoration: none;
  display: block;
}
.dropdown-menu ul li:hover {
  background-color: #333;
  cursor: pointer;
}
